import * as React from 'react';
//import {useEffect} from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './Home.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import BannerImage from '../../images/banner.png';
import WhoWeAreImage from '../../images/who-we-are.png';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LinkMui from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CallImage from '../../images/call.svg';
import LocationImage from '../../images/location.svg';
import MailImage from '../../images/mail.svg';
import PrescriptionImage from '../../images/prescription.svg';
import EasyWorkImage from '../../images/easy-work.svg';
import ExperienceImage from '../../images/experience.svg';
import EasyProcessImage from '../../images/easy-process.svg';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import CircleIcon from '@mui/icons-material/Circle';
import Masonry from '@mui/lab/Masonry';
import Modal from '../../components/Modal/Modal';
import RefillModal from '../../components/Modal/RefillModal';
import { Link, animateScroll as scroll } from "react-scroll";
import AlertDialog from '../../components/Dialog/AlertDialog';
import { LoadingButton } from '@mui/lab';

import Axios from 'axios';


import TouchRipple from '@mui/material/ButtonBase/TouchRipple';

class Home extends React.Component {
	// useEffect(() => {
	// 	Aos.init({
	// 		offset: 0,
	// 		duration: 2000,
	// 		once: 'true',
	// 		startEvent: 'load',
	// 	});
	// 	Aos.refresh();

	// }, [])	

	constructor(props) {
		super(props);
		this.rippleRef = React.createRef(null);
	}
	
	state = {
		contactName: '',
		contactEmail: '',
		contactSubject: '',
		contactMessage: '',
		openAlertDialog: false,
		loadingContactForm: false
	}
	componentDidMount () {
		Aos.init({
			offset: 0,
			duration: 2000,
			once: 'true',
			startEvent: 'load',
		});
		Aos.refresh();
	}
	handleContactSubmit = async ( event ) => {

		event.preventDefault();

		await this.setState({...this.state, loadingContactForm: true })
		
		let contactData = { 'name': this.state.contactName,
							'email': this.state.contactEmail,
							'subject': this.state.contactSubject, 
							'message': this.state.contactMessage,
						};
		// this.state.loadingContactForm
		await Axios.post( 'contact-us/store', contactData )
		.then( async (response) => {
			
			await this.setState({ ...this.state, loadingContactForm: false });
			await this.setState( { contactName: '', contactEmail: '', contactSubject: '', contactMessage: '', openAlertDialog: true } );
		});

	}
	
	handleAlertDialogClose = () => {
		this.setState( { openAlertDialog: false } );
	};
	
	onInputchange = (event) =>  {

		console.log("======================event1");
		console.log(event);
		console.log("======================event2");
		console.log(event.target);
		console.log("======================event3");
		console.log(event.target.name);
		console.log("======================event4");
		if(event !== undefined)
		this.setState({
			[event.target.name]: event.target.value
		});
	}


	// rippleRef = React.useRef(null);
	onRippleStart = (e) => {
		this.rippleRef.current.start(e);
	};
	onRippleStop = (e) => {
		this.rippleRef.current.stop(e);
	};
	
	

	render() {
		return (
			<>
				<Box sx={{ bgcolor: 'primary.light', py: 5 }} id="home">
					<Container maxWidth="lg">
						<Grid container spacing={2} className="banner" position="relative" sx={{ justifyContent: 'space-between', alignItems: 'center', height: '100vh', zIndex: 2 }}>
							<Grid item md={6} sm={12} xs={12} sx={{ pt: 5, pb: 5 }}>
								<Typography
									variant="h1"
									component="h1"
									data-aos="--fade-up"
								>
								Receive your
								</Typography>
								<Box sx={{ display: 'inline-flex', mb: 5, flexWrap: 'wrap' }} data-aos="--fade-up">
									<Typography
										variant="h1"
										component="h1"
									>
									Medication for a&nbsp;
									</Typography> 
									<Typography
										variant="h1"
										component="h1"
										color="secondary"
										sx={{ fontWeight: '800'}}
									>
									$0&nbsp;
									</Typography>
									<Typography
										variant="h1"
										component="h1"
										sx={{ fontWeight: '800'}}
									>
									COPAY
									</Typography>
								</Box>
								<Typography style={{fontSize: "22px"}}
									variant="body1"
									component="p"
									data-aos="--fade-up"
								>
								Your Medication is 100% free, and you help your employer reduce their overall healthcare costs.
								{/* Your employer can save up to 75% on the cost of your medications through our program. We help facilitate medications dispensed both domestically and from canada. */}
								</Typography>
								<Modal 
									open={this.props.open} 
									handleClickOpen={this.props.handleClickOpenModal}
									handleClose={this.props.handleCloseModal}
									handleNext={this.props.handleNextModal} 
									handleBack={this.props.handleBackModal}
									handleReset={this.props.handleResetModal}
									openAlertDialog={this.props.openAlertDialog} 
									handleAlertDialogClose={this.props.handleAlertDialogClose}
									onInputchange={this.props.onInputchange}
									// onInputSelect={this.props.onInputSelect}
									prevActiveStep={this.props.prevActiveStep}
									activeStep={this.props.activeStep}
									completed={this.props.completed}
									scroll={this.props.scroll}
									state={this.props.state} 
									buttanEnable={this.props.buttanEnable}
									loadingMemberForm={this.props.loadingMemberForm}
								/>
								
								<RefillModal 
									
									openRefillModal={this.props.openRefillModal} 
									handleClickOpenRefillModal={this.props.handleClickOpenRefillModal}
									handleClose={this.props.handleCloseRefillModal}
									// handleNext={this.props.handleNextModal} 
									handleSubmitRefill={this.props.handleSubmitRefill}
									// handleBack={this.props.handleBackModal}
									handleReset={this.props.handleResetModal}
									openAlertDialog={this.props.openAlertDialog} 
									handleAlertDialogClose={this.props.handleAlertDialogClose}
									onInputchange={this.props.onInputchange}
									onInputchangeRefillDOB={this.props.onInputchangeRefillDOB}
									// onInputSelect={this.props.onInputSelect}
									prevActiveStep={this.props.prevActiveStep}
									activeStep={this.props.activeStep}
									completed={this.props.completed}
									scroll={this.props.scroll}
									state={this.props.state} 
									buttanEnable={this.props.buttanEnable}
									loadingMemberForm={this.props.loadingMemberForm}
								/>
								
							</Grid>
							<Grid item md={5} sm={12} xs={12} sx={{ pt: 5, pb: 5 }}>
								<Card elevation={0}	height="550px" sx={{ bgcolor: 'inherit' }}
									data-aos="--zoom-in">
									<CardMedia
										style={{ objectFit: 'contain'}}
										component="img"
										image={BannerImage}
										alt="RxFree4me"
									/>
								</Card>
							</Grid>
							<Typography
								variant="subtitle1"
								component="div"
								position="absolute"
								className='subtitle1'
								data-aos="--slide-left"
							>
							RxFree
							</Typography>
						</Grid>
					</Container>
				</Box>
				<Box sx={{py: 5}} id="who-we-are">
					<Container maxWidth="lg">
						<Grid container sx={{ justifyContent: 'space-between' }}>
							<Grid item className="order-sm-2" md={5} sm={12} xs={12} sx={{ pt: 5, pb: 5 }}>
								<Card elevation={0} height="500px" sx={{ bgcolor: 'inherit' }} data-aos="--zoom-in">
									<CardMedia
										component="img"
										image={WhoWeAreImage}
										alt="RxFree4me"
									/>
								</Card>
							</Grid>
							<Grid item className="order-sm-1" md={6} sm={12} xs={12} sx={{ pt: 5, pb: 5 }}>
								<Typography
									variant="subtitle2"
									component="h3"
									data-aos="--fade-up"
								>
								We Are
								</Typography>
								<Typography
									variant="h2"
									component="h2"
									sx={{mb: 4}}
									data-aos="--fade-up"
								>
								RxFree4me
								</Typography>
								<Typography
									variant="body2"
									component="p"
									sx={{mb: 1}}
									data-aos="--fade-up"
								>
								RxFree4me is a pharmacy consulting company that specializes in facilitating medications at a lower cost for employers and their members. Members get their same brand medications delivered to their door for a $0 copay. Our customer support representatives work hand in hand with members, from enrollment through delivery, for a hassle free member experience. Our high-touch approach to customer service makes us the alternative choice to help reduce prescription costs in a meaningful way.
								</Typography>
								<List
									sx={{mb: 1}}
								>
									<ListItem
										disableGutters
										sx={{py: 0}}
										data-aos="--fade-up"
									>
										<ListItemIcon sx={{minWidth: 40}}>
											<CheckBoxOutlinedIcon color="secondary" />
										</ListItemIcon>
										<ListItemText
											primary="Same brand medications, same manufacturers"
											primaryTypographyProps={{
												fontSize: 16,
												lineHeight: 1.7,
											}}
										/>
									</ListItem>
									<ListItem
										disableGutters
										sx={{py: 0}}
										data-aos="--fade-up"
									>
										<ListItemIcon sx={{minWidth: 40}}>
											<CheckBoxOutlinedIcon color="secondary" />
										</ListItemIcon>
										<ListItemText
											primary="Price lock guarantee on all prescriptions for 12 months"
											primaryTypographyProps={{
												fontSize: 16,
												lineHeight: 1.7,
											}}
										/>
									</ListItem>
									<ListItem
										disableGutters
										sx={{py: 0}}
										data-aos="--fade-up"
									>
										<ListItemIcon sx={{minWidth: 40}}>
											<CheckBoxOutlinedIcon color="secondary" />
										</ListItemIcon>
										<ListItemText
											primary="Stop Loss insurance discount"
											primaryTypographyProps={{
												fontSize: 16,
												lineHeight: 1.7,
											}}
										/>
									</ListItem>
								</List>
								<Typography
								variant="body2"
								component="p"
								sx={{mb: 3}}
								data-aos="--fade-up"
								>
								RxFree4me has years of experience reducing prescription drug costs for health plans. Our simple implementation process requires no contract, no vendor changes, no upfront fees, and can be completed in a little as 24 hours. Contact us today to get started reducing prescription costs in a meaningful way.
								</Typography>
							</Grid>
						</Grid>
					</Container>
				</Box>
				<Box position="relative" sx={{ zIndex: 2, pb: 5 }} id="our-services">
					<Container maxWidth="lg">
						<Grid container position="relative" sx={{ justifyContent: 'space-between', alignItems: 'center', zIndex: 2 }}>
							<Grid item md={5} sm={12} xs={12} sx={{ pt: 5, pb: 5 }} data-aos="--fade-up">
								<Typography
									variant="subtitle2"
									component="h3"
								>
								Our
								</Typography>
								<Typography
									variant="h2"
									component="h2"
									sx={{mb: 4}}
								>
								Services
								</Typography>
								<Typography
									variant="body2"
									component="p"
									sx={{mb: 1}}
								>
								RxFree4Me is a pharmacy consulting company specializing in facilitating medications at a lower cost for your employer.
								</Typography>

								<Link
									key={"Contact Us"}
									component="button"
									variant="contained"
									to={"contact-us"}
									spy={true}
									smooth={true}
									offset={-100}
									duration={500}
									pagedata={"contact-us"}
									className="btn-link"
									onMouseDown={()=>this.onRippleStart()}
									onMouseUp={()=>this.onRippleStop()}
									// style={{
									// display: 'inline-block',
									// padding: 8,
									// position: 'relative',
									// border: 'black solid 1px',
									// }}
								>
									Contact Us
									<TouchRipple ref={this.rippleRef} center={false} />
								</Link>
								
							</Grid>
							<Grid item md={6} sm={12} xs={12} sx={{ pt: 5, pb: 5 }} data-aos="--fade-up">
								<Box>
									<Masonry columns={{ xs: 1, sm: 2, md: 2 }} spacing={2} sx={{m: 0}}>
										<Card elevation={0} sx={{bgcolor: '#EAEDFF'}}>
											<CardContent>
												<CardMedia
													component="img"
													image={PrescriptionImage}
													alt="Guaranteed Brand Prescription"
													sx={{objectFit: 'contain', width: 64, height: 64, mb: 1}}
												/>
												<Typography gutterBottom variant="h3" component="h3">
												Guaranteed Brand Prescription
												</Typography>
												<Typography variant="body2" component="div">
												No substitutes! Order brand prescriptions at amazingly low prices. No Vendor Changes.
												</Typography>
											</CardContent>
										</Card>
										<Card elevation={0} sx={{bgcolor: '#FFE7E8'}}>
											<CardContent>
												<CardMedia
													component="img"
													image={EasyWorkImage}
													alt="Easy to work"
													sx={{objectFit: 'contain', width: 64, height: 64, mb: 1}}
												/>
												<Typography gutterBottom variant="h3" component="h3">
												Easy to work
												</Typography>
												<Typography variant="body2" component="div">
												Through our state-of-the-art processing system, full custom automation, and knowledgeable staff, we pride ourselves in making the order process simple.
												</Typography>
											</CardContent>
										</Card>
										<Card elevation={0} sx={{bgcolor: '#FFDCDC'}}>
											<CardContent>
												<CardMedia
													component="img"
													image={ExperienceImage}
													alt="Industry Experience"
													sx={{objectFit: 'contain', width: 64, height: 64, mb: 1}}
												/>
												<Typography gutterBottom variant="h3" component="h3">
												Industry Experience
												</Typography>
												<Typography variant="body2" component="div">
													<ListItem
														disableGutters
														sx={{py: 0, alignItems: 'baseline'}}
													>
														<ListItemIcon sx={{minWidth: 18}}>
															<CircleIcon sx={{fontSize: 8}}/>
														</ListItemIcon>
														<ListItemText
															primary="Over a Decade of Cost Reduction History"
															primaryTypographyProps={{
																fontSize: 16,
																lineHeight: 1.7,
															}}
														/>
													</ListItem>
													<ListItem
														disableGutters
														sx={{py: 0, alignItems: 'baseline'}}
													>
														<ListItemIcon sx={{minWidth: 18}}>
															<CircleIcon sx={{fontSize: 8}}/>
														</ListItemIcon>
														<ListItemText
															primary="Stop Loss Discounts"
															primaryTypographyProps={{
																fontSize: 16,
																lineHeight: 1.7,
															}}
														/>
													</ListItem>
													<ListItem
														disableGutters
														sx={{py: 0, alignItems: 'baseline'}}
													>
														<ListItemIcon sx={{minWidth: 18}}>
															<CircleIcon sx={{fontSize: 8}}/>
														</ListItemIcon>
														<ListItemText
															primary="24/7 Member Support"
															primaryTypographyProps={{
																fontSize: 16,
																lineHeight: 1.7,
															}}
														/>
													</ListItem>
													<ListItem
														disableGutters
														sx={{py: 0, alignItems: 'baseline'}}
													>
														<ListItemIcon sx={{minWidth: 18}}>
															<CircleIcon sx={{fontSize: 8}}/>
														</ListItemIcon>
														<ListItemText
															primary="Price Lock on ALL Rx's Thru the Calendar Year"
															primaryTypographyProps={{
																fontSize: 16,
																lineHeight: 1.7,
															}}
														/>
													</ListItem>
													<ListItem
														disableGutters
														sx={{py: 0, alignItems: 'baseline'}}
													>
														<ListItemIcon sx={{minWidth: 18}}>
															<CircleIcon sx={{fontSize: 8}}/>
														</ListItemIcon>
														<ListItemText
															primary="Flexible process and 24-hr implementation"
															primaryTypographyProps={{
																fontSize: 16,
																lineHeight: 1.7,
															}}
														/>
													</ListItem>
												</Typography>
											</CardContent>
										</Card>
										<Card elevation={0} sx={{bgcolor: '#F3EAFF'}}>
											<CardContent>
												<CardMedia
													component="img"
													image={EasyProcessImage}
													alt="Easy Process"
													sx={{objectFit: 'contain', width: 64, height: 64, mb: 1}}
												/>
												<Typography gutterBottom variant="h3" component="h3">
												Easy Process
												</Typography>
												<Typography variant="body2" component="div">
													<ListItem
														disableGutters
														sx={{py: 0, alignItems: 'baseline'}}
													>
														<ListItemIcon sx={{minWidth: 18}}>
															<CircleIcon sx={{fontSize: 8}}/>
														</ListItemIcon>
														<ListItemText
															primary="Quick Employee Enrollment"
															primaryTypographyProps={{
																fontSize: 16,
																lineHeight: 1.7,
															}}
														/>
													</ListItem>
													<ListItem
														disableGutters
														sx={{py: 0, alignItems: 'baseline'}}
													>
														<ListItemIcon sx={{minWidth: 18}}>
															<CircleIcon sx={{fontSize: 8}}/>
														</ListItemIcon>
														<ListItemText
															primary="Easy Click, Call or Text to Enroll"
															primaryTypographyProps={{
																fontSize: 16,
																lineHeight: 1.7,
															}}
														/>
													</ListItem>
													<ListItem
														disableGutters
														sx={{py: 0, alignItems: 'baseline'}}
													>
														<ListItemIcon sx={{minWidth: 18}}>
															<CircleIcon sx={{fontSize: 8}}/>
														</ListItemIcon>
														<ListItemText
															primary="No Vendor Changes"
															primaryTypographyProps={{
																fontSize: 16,
																lineHeight: 1.7,
															}}
														/>
													</ListItem>
													<ListItem
														disableGutters
														sx={{py: 0, alignItems: 'baseline'}}
													>
														<ListItemIcon sx={{minWidth: 18}}>
															<CircleIcon sx={{fontSize: 8}}/>
														</ListItemIcon>
														<ListItemText
															primary="No Upfront Charges"
															primaryTypographyProps={{
																fontSize: 16,
																lineHeight: 1.7,
															}}
														/>
													</ListItem>
													<ListItem
														disableGutters
														sx={{py: 0, alignItems: 'baseline'}}
													>
														<ListItemIcon sx={{minWidth: 18}}>
															<CircleIcon sx={{fontSize: 8}}/>
														</ListItemIcon>
														<ListItemText
															primary="No % of Savings or PEPM Charges"
															primaryTypographyProps={{
																fontSize: 16,
																lineHeight: 1.7,
															}}
														/>
													</ListItem>
												</Typography>
											</CardContent>
										</Card>
									</Masonry>
								</Box>
							</Grid>
							<Typography
								variant="subtitle1"
								component="div"
								position="absolute"
								className='subtitle2'
								data-aos="--slide-right"
							>
							Ser
							</Typography>
							<Typography
								variant="subtitle1"
								component="div"
								position="absolute"
								className='subtitle4'
								data-aos="--slide-left"
							>
							Ces
							</Typography>
						</Grid>
					</Container>
					<Typography
						variant="subtitle1"
						component="div"
						position="absolute"
						className='subtitle3'					
						data-aos="--slide-left"
					>
					Vi
					</Typography>
				</Box>
				<Box sx={{bgcolor: 'primary.light', py: 10}} id="why-choose-us">
					<Container maxWidth="lg">
						<Box sx={{textAlign: 'center', mb: 4}}>
							<Typography
								variant="subtitle2"
								component="h3"					
								data-aos="--fade-up"
							>
							Why
							</Typography>
							<Typography
								variant="h2"
								component="h2"
								sx={{mb: 4}}			
								data-aos="--fade-up"
							>
							Choose Us
							</Typography>
							<Typography
								variant="body2"
								component="p"
								sx={{mb: 1}}
								data-aos="--fade-up"		
							>
							RxFree4me has years of experience reducing prescription drug costs for health plans. Our simple implementation process requires no contract, no vendor changes, and no upfront fees and can be completed in as little as 24 hours.
							</Typography>
						</Box>
						<Grid container sx={{ justifyContent: 'space-around' }}>
							<Grid item md={4} sm={6} xs={12}>
								<Card elevation={0} sx={{bgcolor: 'transparent'}} data-aos="--fade-up">
									<CardContent sx={{position: 'relative'}}>
										<Typography
										sx={{fontFamily: 'Poppins', fontSize: 100, lineHeight: 1, color: 'primary.main'}} 
										className='choose-no'>
										01
										</Typography>
										<Typography gutterBottom variant="h3" component="h3" sx={{ color: 'primary.main', pl: 4, py: 3}}> 
										Our Mission
										</Typography>
										<Typography variant="body2">
										To provide our members and clients access to necessary medication at the lowest possible cost, while making the process as simple as possible
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid item md={4} sm={6} xs={12}>
								<Card elevation={0} sx={{bgcolor: 'transparent'}} data-aos="--fade-up">
									<CardContent sx={{position: 'relative'}}>
										<Typography
										sx={{fontFamily: 'Poppins', fontSize: 100, lineHeight: 1, color: 'primary.main'}} 
										className='choose-no'>
										02
										</Typography>
										<Typography gutterBottom variant="h3" component="h3" sx={{ color: 'primary.main', pl: 4, py: 3}}>
										Prescription cost saving
										</Typography>
										<Typography variant="body2">
										Once you enrolled, a RxFree4me Representative will contact you regarding the next steps.
										</Typography>
										<Typography variant="body2" color="black">
										Call: <LinkMui href='tel:8447943733' color='inherit' underline='none'>(844) 794-3733</LinkMui> | Enroll online in less than a minute
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid item md={4} sm={6} xs={12}>
								<Card elevation={0} sx={{bgcolor: 'transparent'}} data-aos="--fade-up">
									<CardContent sx={{position: 'relative'}}>
										<Typography
										sx={{fontFamily: 'Poppins', fontSize: 100, lineHeight: 1, color: 'primary.main'}} 
										className='choose-no'>
										03
										</Typography>
										<Typography gutterBottom variant="h3" component="h3" sx={{ color: 'primary.main', pl: 4, py: 3}}>
										Customer support
										</Typography>
										<Typography variant="body2">
										Our customer support representatives are enthusiastic to resolve any issue quickly and professionally.
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid item md={4} sm={6} xs={12}>
								<Card elevation={0} sx={{bgcolor: 'transparent'}} data-aos="--fade-up">
									<CardContent sx={{position: 'relative'}}>
										<Typography
										sx={{fontFamily: 'Poppins', fontSize: 100, lineHeight: 1, color: 'primary.main'}} 
										className='choose-no'>
										04
										</Typography>
										<Typography gutterBottom variant="h3" component="h3" sx={{ color: 'primary.main', pl: 4, py: 3}}>
										Employer advantage
										</Typography>
										<Typography variant="body2">
										No PBM Change<br/>
										No Consultant Change<br/>
										No Implementation Fees<br/>
										No Percentage of Savings Charges<br/>
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid item md={4} sm={6} xs={12}>
								<Card elevation={0} sx={{bgcolor: 'transparent'}} data-aos="--fade-up">
									<CardContent sx={{position: 'relative'}}>
										<Typography
										sx={{fontFamily: 'Poppins', fontSize: 100, lineHeight: 1, color: 'primary.main'}} 
										className='choose-no'>
										05
										</Typography>
										<Typography gutterBottom variant="h3" component="h3" sx={{ color: 'primary.main', pl: 4, py: 3}}>
										Member advantage
										</Typography>
										<Typography variant="body2">
										Same medications<br/>
										Same Manufacturers<br/>
										Zero Copay<br/>
										Prescriptions mailed to your home<br/>
										</Typography>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</Container>
				</Box>
				<Box sx={{py: 5 }} id="contact-us">
					<Container maxWidth="lg">
						<Grid container sx={{ justifyContent: 'space-between' }}>
							<Grid item md={6} sm={12} xs={12} sx={{ pt: 5, pb: 5 }}>
								<Typography
									variant="subtitle2"
									component="h3"
									data-aos="--fade-up"
								>
								Get in
								</Typography>
								<Typography
									variant="h2"
									component="h2"
									sx={{mb: 4}}
									data-aos="--fade-up"
								>
								Touch
								</Typography>
								<Typography
									variant="body2"
									component="p"
									sx={{mb: 1}}
									data-aos="--fade-up"
								>
								Our customer support representatives are enthusiastic about resolving any issue quickly and professionally.
								</Typography>
								<List
									sx={{mb: 2}}
								>
									<ListItem
										disableGutters
										data-aos="--fade-up"
									>
										<ListItemIcon>
											<img 
											src={CallImage}
											srcSet={CallImage}
											alt='Call'
											height={30}
											loading="lazy"
											/>
										</ListItemIcon>
										<ListItemText
											primary={<LinkMui href='tel:8447943733' color='inherit' underline='none'>
											(844) 794-3733</LinkMui>}
											primaryTypographyProps={{
												fontSize: 16,
												lineHeight: 1.7,
											}}
										/>
									</ListItem>
									<ListItem
										disableGutters
										data-aos="--fade-up"
										style={{display: "none"}}
									>
										<ListItemIcon>
											<img 
											src={LocationImage} 
											srcSet={LocationImage}
											alt='Location'
											height={30}
											loading="lazy"
											/>
										</ListItemIcon>
										<ListItemText
											primary="18230 Mack Ave, Grosse Pointe Farms, MI 48236"
											primaryTypographyProps={{
												fontSize: 16,
												lineHeight: 1.7,
											}}
										/>
									</ListItem>
									<ListItem
										disableGutters
										data-aos="--fade-up"
									>
										<ListItemIcon>
											<img 
											src={MailImage}
											srcSet={MailImage}
											alt='Mail'
											height={30}
											loading="lazy"
											/>
										</ListItemIcon>
										<ListItemText
											primary={<LinkMui href='mailto:info@RxFree4me.com' color='inherit' underline='none'>
											info@RxFree4me.com</LinkMui>}
											primaryTypographyProps={{
												fontSize: 16,
												lineHeight: 1.7,
											}}
										/>
									</ListItem>
								</List>
								<Box data-aos="--fade-up">
									<LinkMui href='/' underline='none' sx={{mr: 2}}>
										<FacebookOutlinedIcon color="primary"/>
									</LinkMui>
									<LinkMui href='/' underline='none' sx={{mr: 2}}>
										<InstagramIcon color="primary"/>
									</LinkMui>
									<LinkMui href='/' underline='none' sx={{mr: 2}}>
										<TwitterIcon color="primary"/>
									</LinkMui>
								</Box>
							</Grid>
							<Grid item md={5} sm={12} xs={12} sx={{ pt: 5, pb: 5}}>
								<Box
								method='post'
								onSubmit={(event) => this.handleContactSubmit(event)}
								component="form"
								autoComplete="off"
								data-aos="--fade-up"
								>
									<Grid container spacing={2} sx={{ justifyContent: 'space-between' }}>
										<Grid item md={6} sm={4} xs={12}>
											<TextField
											required
											name="contactName"
											id="name"
											label="Name"
											helperText=" "
											fullWidth
											onChange={(e) => this.onInputchange(e)}
											value={this.state.contactName}
											/>
										</Grid>
										<Grid item md={6} sm={4} xs={12}>
											<TextField
											required
											name="contactEmail"
											//error
											id="email"
											label="Email"
											// helperText="Incorrect email."
											fullWidth
											onChange={(e) => this.onInputchange(e)}
											value={this.state.contactEmail}
											/>
										</Grid>
										<Grid item md={12} sm={4} xs={12}>
											<TextField
											fullWidth
											name="contactSubject"
											required
											id="subject"
											label="Subject"
											helperText=" "
											onChange={(e) => this.onInputchange(e)}
											value={this.state.contactSubject}
											/>
										</Grid>
										<Grid item md={12} sm={12} xs={12}>
											<TextField
											fullWidth
											required
											name="contactMessage"
											id="message"
											label="Message"
											multiline
											rows={4}
											helperText=" "
											onChange={(e) => this.onInputchange(e)}
											value={this.state.contactMessage}
											/>
										</Grid>
									</Grid>
									<Grid container sx={{ justifyContent: 'flex-end' }}>
										{/* <Button color="primary" variant="contained" disableElevation sx={{borderRadius: '25px'}} type="submit" >Submit</Button> */}
										<LoadingButton 
											type="submit"
											color="primary" 
											loading={ this.state.loadingContactForm } 
											disableElevation 
											sx={{borderRadius: '25px'}} 
											variant="contained" 
										>
											Submit
										</LoadingButton>
									</Grid>
								</Box>
							</Grid>
						</Grid>
					</Container>
				</Box>

				<AlertDialog 
				openAlertDialog={this.state.openAlertDialog} 
				handleAlertDialogClose={() => this.handleAlertDialogClose()} message = "Thanks for contact us." />
			</>	
		);
	}
}
export default Home;